import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-showndown',
  templateUrl: './showndown.component.html',
  styleUrls: ['./showndown.component.less'],
})
export class ShowndownComponent implements OnInit {

  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit() {
    // @ts-ignore
    const converter = new showdown.Converter();
    converter.setOption('optionKey', 'value');

    // const text = '# hello, world!';
    let html = converter.makeHtml('');

    const showdownDiv = document.querySelector('.showdown') as HTMLElement;
    showdownDiv.innerHTML = html;

    let assetURI = this.router.url;

    assetURI = '/assets' + assetURI;

    if (assetURI.endsWith('/') === false) {
      assetURI += '/';
    }

    // @ts-ignore
    hljs.initHighlightingOnLoad();

    console.log(assetURI + 'index.md');
    this.http.get(assetURI + 'index.md', {responseType: 'text'}).subscribe(
      res => {
        html = converter.makeHtml(res);

        // Find relative <img src="" links and construct an absolute path
        const myRegexp = /<img src="([^"]*)"/g;
        let match = myRegexp.exec(html);
        while (match != null) {
          let imgTag = match[0]; // <img src="img.png"
          let imgUrl = match[1]; // img.png
          console.log(imgTag);
          console.log(imgUrl);

          // Image is being referred to via relative URI
          if (imgUrl.indexOf('://') === -1) {
            imgUrl = assetURI + imgUrl;

            imgTag = imgTag.replace(match[1], imgUrl);

            while (html.indexOf(match[0]) !== -1) {
              html = html.replace(match[0], imgTag);
            }
            console.log('NEW imgTag: ' + imgTag);
          }

          match = myRegexp.exec(html);
        }

        showdownDiv.innerHTML = html;

        document.querySelectorAll('pre code').forEach((block) => {
            // @ts-ignore
            hljs.highlightBlock(block);
        });
      },
      error => {
        alert(JSON.stringify(error));
      }
    );
  }

}
